import { useEffect } from "react";
import { config } from "../config";

export const useLocalStorageVersion = () => {
  const currentBrowserVersion = localStorage.getItem("version");
  const configVersion = config.localStorageVersion;
  useEffect(() => {
    if (currentBrowserVersion && +currentBrowserVersion < +configVersion) {
      localStorage.clear();
    }

    localStorage.setItem("version", configVersion);
  }, []);
};
