import * as Other from "../../Api/Other";

export const appGetCommonStatistic = (requestHelper, isMounted, setCommonStatistic) => {
    requestHelper(Other.getCommonStatistics)
        .then((data) => {
            if (data.status === 'failure') {
                isMounted && setCommonStatistic([]);
            } else {
                isMounted && setCommonStatistic(data);
            }
        })
        .catch((err) => {
            console.error(err);
            //throw new Error(err.status);
        })
}