import { useEffect, useState } from "react";

function useSectorCircleVote(usersPercentage) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    let isMount = true;

    if (usersPercentage === 0) {
      isMount && setValue(2);
    } else if (usersPercentage === 100) {
      isMount && setValue(1);
    } else {
      isMount && setValue(usersPercentage / 100);
    }

    return () => {
      isMount = false;
    };
  }, [usersPercentage]);

  return [value, setValue];
}
export default useSectorCircleVote;
