import {useEffect, useState} from "react";

function useSetRuleQuestionEQ(startValueInput, endValueInput, activeRuleSelect) {

    const [valueRuleEQ, setValueRuleEQ] = useState(0);

    useEffect(() => {
        let isMount = true;

        if (activeRuleSelect === true && startValueInput === endValueInput) {
            isMount && setValueRuleEQ(startValueInput)
        } else {
            isMount && setValueRuleEQ(-1);
        }

        return () => {
            isMount = false;
        }
    },[startValueInput, endValueInput, activeRuleSelect])

    return {valueRuleEQ};
}

export default useSetRuleQuestionEQ;