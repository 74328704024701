import React, { useEffect, useState } from "react";
import GeneralTitleAllPages from "../GeneralTitleAllPages/GeneralTitleAllPages";
import DetailsVoteGeneralInfo from "../DetailsVoteGeneralInfo/DetailsVoteGeneralInfo";
import DetailsVoteStatisticsVoteSingle from "../DetailsVoteStatisticsVote/DetailsVoteStatisticsVoteSingle";
import DetailsVoteVotingObserversCounting from "../DetailsVoteVotingObserversCounting/DetailsVoteVotingObserversCounting";
import DetailsVoteQuestions from "../DetailsVoteQuestions/DetailsVoteQuestions";
import DetailsVoteResultsSingle from "../DetailsVoteResults/DetailsVoteResultsSingle";
import iconRowBack from "../../img/back-button-icon.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Events from "../../Api/Events";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";

const DetailsVotePage = (props) => {
  const {
    constants,
    authAs,
    requestHelper,
    activatePreloader,
    disactivatePreloader,
    handleResetIsSelectedTemplateMode,
    formatTime,
    formatDate,
    isReloadPage,

    isStatisticsUpdate,
    setIsStatisticsUpdate,
  } = props;

  const { runUpdateDataEvents } = useCurrentUserContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectMenuItem, setSelectMenuItem] = useState(0);
  const [hideMenuBlockMobile, setHideMeniBlockMobile] = useState(false);
  const [selectMenuComponent, setSelectMenuComponent] =
    useState("statisticsVote");
  const [currentEventData, setCurrentEventData] = useState({});
  const [isVotersList, setVotersList] = useState([]);
  const [isObserversList, setObserversList] = useState([]);
  const [isCountersList, setCountersList] = useState([]);
  const [isStatisticsVote, setStatisticsVote] = useState([]);
  const [isUpdateStatisticsStep, setUpdateStatisticsStep] = useState(0);
  const informationMenuItems = [
    {
      nameItem: "Общая информация",
      iconClassName: "details-vote__icon-general-info",
    },
    {
      nameItem: "Статистика голосования",
      iconClassName: "details-vote__icon-statistics",
    },
    { nameItem: "Голосующие", iconClassName: "details-vote__icon-voting" },
    { nameItem: "Наблюдатели", iconClassName: "details-vote__icon-observers" },
    {
      nameItem: "Счетная комиссия",
      iconClassName: "details-vote__icon-counting",
    },
    {
      nameItem:
        currentEventData?.status === "ended" ||
        currentEventData?.status === "quorum_unpresant"
          ? "Результаты"
          : "Ознакомиться с вопросами",
      iconClassName: "details-vote__icon-questions",
    },
  ];

  function getVoters(voteId) {
    requestHelper(Events.getVoters, voteId)
      .then((data) => {
        if (data) {
          setVotersList(data);
        } else {
          setVotersList([]);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  function getObservers(voteId) {
    requestHelper(Events.getObservers, voteId)
      .then((data) => {
        if (data) {
          setObserversList(data);
        } else {
          setObserversList([]);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  function getCounters(voteId) {
    requestHelper(Events.getCounters, voteId)
      .then((data) => {
        if (data) {
          setCountersList(data);
        } else {
          setCountersList([]);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  function getStatisticsVote(voteId) {
    requestHelper(Events.getStatistics, voteId)
      .then((data) => {
        if (data) {
          setStatisticsVote(data);
        } else {
          setStatisticsVote([]);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  useEffect(() => {
    if (isReloadPage) {
      getCurrentEvent();
    }
  }, [isReloadPage]);

  function updateStatisticsVote(voteId) {
    requestHelper(Events.updateStatistics, voteId)
      .then((data) => {
        if (data) {
          console.log(data);
        } else {
          navigate("/list-votes");
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  const { id } = useParams();

  function getCurrentEvent() {
    const currentEvent = localStorage.getItem("currentEvent");
    if (id || currentEvent) {
      activatePreloader();

      let event = null;

      if (currentEvent) {
        event = JSON.parse(currentEvent);
      }

      const body = {
        id: event.id || id,
      };

      requestHelper(Events.getEvent, body)
        .then((data) => {
          if (data) {
            setCurrentEventData(data);
            setUpdateStatisticsStep(
              data?.config?.general?.statistics_step * 60000
            );
            getVoters(body);
            getObservers(body);
            getCounters(body);
            getStatisticsVote(body);
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          navigate("*");
          activatePreloader();
          throw new Error(err.message);
        })
        .finally(() => {
          disactivatePreloader();
        });
    } else {
      navigate("/");
    }
  }

  useEffect(() => {
    let isMounted = true;
    isMounted && getCurrentEvent();
    return () => {
      isMounted = false;
    };
  }, [runUpdateDataEvents]);

  const timeIntervalStatisticsStep = () => {
    const body = {
      id: currentEventData.id,
    };
    updateStatisticsVote(body);
  };

  useEffect(() => {
    if (isUpdateStatisticsStep <= 0) {
      return;
    } else if (authAs === "ROLE_COUNTER") {
      return;
    } else if (authAs === "ROLE_OBSERVER") {
      return;
    }
    const timerId = setInterval(
      timeIntervalStatisticsStep,
      isUpdateStatisticsStep
    );
    return () => clearInterval(timerId);
  }, [isUpdateStatisticsStep]);

  const onSelectMenuItems = (i, item) => {
    const getWightBlock =
      document.getElementById("getWidthMainBlock").clientWidth;
    setSelectMenuItem(i);
    if (getWightBlock < 600) {
      switch (item) {
        case "Общая информация":
          setSelectMenuComponent("generalInfo");
          setHideMeniBlockMobile(true);
          break;
        case "Статистика голосования":
          setSelectMenuComponent("statisticsVote");
          setHideMeniBlockMobile(true);
          break;
        case "Голосующие":
          setSelectMenuComponent("voting");
          setHideMeniBlockMobile(true);
          break;
        case "Наблюдатели":
          setSelectMenuComponent("observers");
          setHideMeniBlockMobile(true);
          break;
        case "Счетная комиссия":
          setSelectMenuComponent("counting");
          setHideMeniBlockMobile(true);
          break;
        case "Ознакомиться с вопросами":
          setSelectMenuComponent("questions");
          setHideMeniBlockMobile(true);
          break;
        case "Результаты":
          setSelectMenuComponent("results");
          setHideMeniBlockMobile(true);
          break;
        default: {
        }
      }
    } else {
      switch (item) {
        case "Общая информация":
          setSelectMenuComponent("generalInfo");
          break;
        case "Статистика голосования":
          setSelectMenuComponent("statisticsVote");
          break;
        case "Голосующие":
          setSelectMenuComponent("voting");
          break;
        case "Наблюдатели":
          setSelectMenuComponent("observers");
          break;
        case "Счетная комиссия":
          setSelectMenuComponent("counting");
          break;
        case "Ознакомиться с вопросами":
          setSelectMenuComponent("questions");
          break;
        case "Результаты":
          setSelectMenuComponent("results");
          break;
        default: {
        }
      }
    }
  };

  function backMenuDetailVoteMobile() {
    setHideMeniBlockMobile(false);
    setSelectMenuComponent("");
  }

  useEffect(() => {
    let isMounted = true;
    const getWightBlock =
      document.getElementById("getWidthMainBlock").clientWidth;
    if (getWightBlock < 600) {
      isMounted && setSelectMenuComponent("");
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // connect123

  // useEffect(() => {
  //   let client = Stomp.client(config.ws_connect);
  //   const eventId = localStorage.getItem("currentEvent")?.id;

  //   console.log('i am here', eventId);

  //   if (eventId) {

  //     const on_connect = function () {
  //       client.subscribe(`/exchange/${eventId}/event_statistics`, (message) => {
  //         console.log('client: ', client);
  //         console.log('client.lastMessage: ', client.lastMessage);
  //         const data = JSON.parse(client.lastMessage.body);

  //         console.log('data: ', data);
  //         console.log('message??:', message);

  //         console.log('message body??:', message.body);

  //         // setStatisticsVote(data);
  //       }, {});
  //     };
  //     const on_error = function (err, tmp) {};

  //     client.connect(config.ws_user, config.ws_pass, on_connect, on_error, "/");
  //     client.onWebSocketClose = function (evt) {};
  //   }

  // }, [])

  return (
    <div className="details-vote__container _container _container_single-page">
      {hideMenuBlockMobile ? (
        <div className="details-vote__row-back-votes-mobile">
          <div
            onClick={backMenuDetailVoteMobile}
            className="details-vote__link-row"
          >
            <img src={iconRowBack} alt={constants.GENERAL.ALT_ICON} />
            {constants.DETAILS_VOTE.INFORMATION_MENU_ROW_BACK_MENU}
          </div>
        </div>
      ) : (
        <div className="details-vote__row-back-votes-mobile">
          <Link
            className="details-vote__link-row"
            to={pathname === "/details-vote" ? "/list-votes" : "/details-vote"}
          >
            <img src={iconRowBack} alt={constants.GENERAL.ALT_ICON} />
            {constants.DETAILS_VOTE.INFORMATION_MENU_ROW_BACK_VOTE_LIST}
          </Link>
        </div>
      )}
      <div
        id={"getWidthMainBlock"}
        className="details-vote__main-block statistics-page"
      >
        {selectMenuComponent === "statisticsVote" ? (
          <DetailsVoteStatisticsVoteSingle
            formatTime={formatTime}
            formatDate={formatDate}
            statisticsVoteTable={isStatisticsVote}
            currentEventData={currentEventData}
            requestHelper={requestHelper}
            constants={constants}
            setSelectMenuComponent={setSelectMenuComponent}
            isStatisticsUpdate={isStatisticsUpdate}
            setIsStatisticsUpdate={setIsStatisticsUpdate}
            withRegData={currentEventData?.highlight_registered_in_statistics}
          />
        ) : null}

        {selectMenuComponent === "results" ? (
          <DetailsVoteResultsSingle
            constants={constants}
            requestHelper={requestHelper}
            currentEventData={currentEventData}
          />
        ) : null}
      </div>
    </div>
  );
};
export default DetailsVotePage;
