import "./Loader.scss";

const Loader = ({ isLarge = false }) => {
  return (
    <div className={`loader${isLarge ? " loader_lg" : ""}`}>
      <span className="loader__bar"></span>
      <span className="loader__bar"></span>
      <span className="loader__bar"></span>
    </div>
  );
};

export default Loader;
