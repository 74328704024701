import {useEffect, useState} from "react";

function useSetRuleQuestionGE(startValueInput, endValueInput, activeRuleSelect, rows) {

    const [valueRuleGE, setValueRuleGE] = useState(0);

    useEffect(() => {
        let isMount = true;

        if (activeRuleSelect === true && endValueInput === rows.length && startValueInput !== 1 && endValueInput !== startValueInput) {
            isMount && setValueRuleGE(startValueInput)
        } else {
            isMount && setValueRuleGE(-1);
        }

        return () => {
            isMount = false;
        }
    },[startValueInput, endValueInput, activeRuleSelect, rows])

    return {valueRuleGE};
}

export default useSetRuleQuestionGE;