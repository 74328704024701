import React from "react";

const CardQuestionGraphNameColumnGrid = ({ nameColumn, colorSquare }) => {
  const styleSquare = {
    background: colorSquare,
    width: "10px",
    height: "10px",
    opacity: "1",
    position: "absolute",
  };

  return (
    <div className={"card-question-graph-column__wrapper"}>
      <div className="graph-square" style={styleSquare}></div>
      <span className={"card-question-graph-column__name-column"}>
        {nameColumn}
      </span>
    </div>
  );
};

export default CardQuestionGraphNameColumnGrid;
