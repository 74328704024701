import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import {BrowserRouter} from "react-router-dom";
import {CurrentUserContextProvider} from "./contexts/CurrentUserContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
        staleTime: Infinity,
      },
    },
  });

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <CurrentUserContextProvider>
                <App/>
            </CurrentUserContextProvider>
        </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
