import React, { useEffect, useState } from "react";
import row_input_select_role from "../../img/Auth_icon_row_select_role.svg";
import { setCorrectNameProtocol } from "../../utils/SetCorrectNameProtocol/setCorrectNameProtocol";
import { useOnClickOutsideModal } from "../../utils/CustomHooks/useOutsideModal/useOutsideModal";

const AddNewVoteTemplateProtocol = (props) => {
  const { constants, currentOrg, isSelectedProtocol, setSelectedProtocol } =
    props;

  const [isActiveSelectProtocol, setActiveSelectProtocol] = useState(false);
  const [isProtocolSelectList, setProtocolSelectList] = useState([]);
  useOnClickOutsideModal(isActiveSelectProtocol, () =>
    setActiveSelectProtocol(false)
  );

  const onHandleSelectProtocol = (protocol) => {
    setSelectedProtocol(protocol);
  };

  const onAddDefaultProtocolInProtocolList = () => {
    const protocols = [];
    currentOrg?.config?.protocol?.org_template_links?.forEach((item) => {
      if (item !== currentOrg?.config?.event_template_links?.default) {
        protocols.push(item);
      }
    });
    setProtocolSelectList([
      ...protocols,
      currentOrg?.config?.event_template_links?.default,
    ]);
  };

  useEffect(() => {
    let isMounted = true;
    if (currentOrg) {
      isMounted &&
        setSelectedProtocol(currentOrg?.config?.event_template_links?.default);
      isMounted && onAddDefaultProtocolInProtocolList();
    }
    return () => {
      isMounted = false;
    };
  }, [currentOrg]);

  return (
    <div className="add-new-vote-template-protocol">
      <h3 className="add-new-vote-template-protocol__title">
        {constants.ADD_NEW_VOTE.TEMPLATE_TITLE_PROTOCOL}
      </h3>
      <div className="add-new-vote__select-role">
        <label className="add-new-vote__label">
          {constants.ADD_NEW_VOTE.TEMPLATE_SELECT_PROTOCOL}
          <span className="add-new-vote__red-star">*</span>
        </label>
        <div
          onClick={() => setActiveSelectProtocol(!isActiveSelectProtocol)}
          className="add-new-vote__time-zone-select-container"
        >
          <p className="add-new-vote__time-zone-select-value">
            {setCorrectNameProtocol(isSelectedProtocol)}
          </p>
          <img
            className="add-new-vote__time-zone-select-arrow"
            src={row_input_select_role}
            alt="Стрелочка открытия меню"
          />
          <div
            className={
              isActiveSelectProtocol
                ? "add-new-vote__time-zone-options-container"
                : "add-new-vote__time-zone-options-container hidden"
            }
          >
            {isProtocolSelectList.map((el, i) => {
              return (
                <div
                  key={i}
                  className="add-new-vote__time-zone-option-container"
                  onClick={() => onHandleSelectProtocol(el)}
                >
                  <p className="add-new-vote__time-zone-option">
                    {setCorrectNameProtocol(el)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddNewVoteTemplateProtocol;
