import React from "react";
import CardQuestionHorizontalGraphColumnRow from "../CardQuestionHorizontalGraphColumn/CardQuestionHorizontalGraphColumnRow";
import CardQuestionHorizontalGraphColumnRowInvalidBallots from "../CardQuestionHorizontalGraphColumn/CardQuestionHorizontalGraphColumnRowInvalidBallots";

const CardQuestionHorizontalGraphRow = (props) => {
  const { numInvalid, resultVote, votedUsers } = props;

  const stub = false; //2) Без испорченых биллютеней - опция организации

  return (
    <div className={"card-question-horizontal-graph__horizontal-grid"}>
      <table className={"horizontal-grid__position-table"}>
        <tbody>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
      {resultVote.map((el) => {
        return (
          <CardQuestionHorizontalGraphColumnRow
            key={el.id}
            result={el.columns[0].favor}
            votedPercent={
              el.columns[0].favor > 0
                ? ((el.columns[0].favor / votedUsers) * 100).toFixed(1)
                : 0
            }
            colorColumns={el.color}
            votedUsers={votedUsers}
          />
        );
      })}
      {stub && (
        <CardQuestionHorizontalGraphColumnRowInvalidBallots
          numInvalid={numInvalid}
          votedUsers={votedUsers}
        />
      )}
    </div>
  );
};
export default CardQuestionHorizontalGraphRow;
