import React, { useEffect, useState } from "react";
import GeneralTitleAllPages from "../GeneralTitleAllPages/GeneralTitleAllPages";
import PaginationBlock from "../PaginationBlock/PaginationBlock";
import iconDeleteBnt from "../../img/AddNewOrgDeleteIcon.svg";
import iconSelectName from "../../img/GroupUsersNameGroupSelectIcon.svg";
import * as Moderators from "../../Api/Moderators";
import { Validation } from "../../utils/Validation/Validation";
import * as Users from "../../Api/Users";
import GroupUsersDeleteModalUsers from "../GroupUsersDeleteModalUsers/GroupUsersDeleteModalUsers";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import GroupUsersDeleteModalSomeUsers from "../GroupUsersDeleteModalSomeUsers/GroupUsersDeleteModalSomeUsers";
import { onSetCountPageForSearch } from "../../utils/OnSetCountPageForSearch/OnSetCountPageForSearch";

const ListModerators = (props) => {
  const { constants, requestHelper, allModerators } = props;

  const { runUpdateDataEvents } = useCurrentUserContext();
  const usersTextarea = Validation();
  const [activeAddUsersForm, setActiveAddUsersForm] = useState(false);
  const [currentGroupUsers, setCurrentGroupUsers] = useState([]);
  const [usersToFind, setUsersToFind] = useState([]);
  const [isActiveModalDeleteUsers, setActiveModalDeleteUsers] = useState(null);
  const [isActiveModalDeleteSomeUsers, setActiveModalDeleteSomeUsers] =
    useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const regex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const usersSearch = Validation();
  const [usersForRender, setUsersForRender] = useState([]);
  const [usersSearchInput, setUsersSearchInput] = useState("");
  const [showResultsFrom, setShowResultsFrom] = useState(0);
  const [resultsShow, setResultsShow] = useState(5);
  const [result, setResult] = useState(5);
  const [pageCount, setPageCount] = useState(1);
  const [selectedResultsShow, setSelectedResultsShow] = useState(5);

  const [notFoundedUsers, setNotFoundedUsers] = useState("");

  function onGetCurrentModeratorsUsers(allUsers) {
    const users = [];
    if (allUsers && allModerators.length > 0) {
      allUsers.forEach((el) => {
        const newUser = {
          id: el?.id,
          email: el?.email,
          first_name:
            el?.first_name === undefined
              ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}`
              : el?.first_name,
          last_name:
            el?.last_name === undefined
              ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}`
              : el?.last_name,
          second_name:
            el?.second_name === undefined
              ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}`
              : el?.second_name,
          isChecked: false,
        };
        users.push(newUser);
      });
    }

    setCurrentGroupUsers(users);
  }

  useEffect(() => {
    let isMounted = true;
    isMounted && onGetCurrentModeratorsUsers(allModerators);
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [allModerators]);

  useEffect(() => {
    let isMounted = true;
    if (usersToFind.length !== 0) {
      requestHelper(Users.findUsers, usersToFind)
        .then((data) => {
          const users = [];

          const notFoundedArray = [];
          Object.values(data).forEach((user) => {
            if (user === null) {
              notFoundedArray.push(
                Object.keys(data).find((key) => data[key] === user)
              );
            }
            if (user) {
              const newUser = {
                id: user.id,
                email: user.email,
                first_name: user.lastName
                  ? user.firstName
                  : `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}`,
                last_name: user.lastName
                  ? user.lastName
                  : `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}`,
                second_name: user.lastName
                  ? user.secondName
                  : `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}`,
                isAdmin: false,
                isChecked: false,
                userFields: user.userFields,
              };
              isMounted && users.push(newUser);
            }
          });
          const filtredArray = [];
          users.forEach((item) => {
            const findItem = currentGroupUsers.find((el) => el.id === item.id);
            if (findItem === undefined) {
              isMounted && filtredArray.push(item);
            }
          });

          setNotFoundedUsers(notFoundedArray.join(", "));

          isMounted &&
            setCurrentGroupUsers([...currentGroupUsers, ...filtredArray]);
        })
        .catch((err) => {
          throw new Error(err.message);
        });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [usersToFind]);

  function findUsers() {
    const usersArray = usersTextarea.value.trim().split(/(?:\n| |,)+/);
    const validUsersEmails = [];
    usersArray.forEach((user) => {
      if (regex.test(String(user).toLowerCase())) {
        validUsersEmails.push(user);
      }
    });
    const uniq = [...new Set(validUsersEmails)];
    if (uniq.length !== 0) {
      setUsersToFind(uniq);
      setEmailErrorMessage("");
    } else {
      setUsersToFind([]);
      setEmailErrorMessage(constants.ADD_NEW_ORG.ADD_NEW_ORG_EMAIL_ERROR);
    }
  }

  function closeModalDeleteUser() {
    setActiveModalDeleteUsers(null);
  }

  function onOpenModalDeleteSomeUsers() {
    setActiveModalDeleteUsers(null);
    setActiveModalDeleteSomeUsers(true);
  }

  function closeModalDeleteSomeUsers() {
    setCurrentGroupUsers(
      currentGroupUsers.map(function (item) {
        return { ...item, isChecked: false };
      })
    );
    setActiveModalDeleteSomeUsers(false);
  }

  const onHandleCheckTable = (id) => {
    const currentUser = currentGroupUsers.find((el) => el.id === id);
    if (currentUser.isChecked === false) {
      const changedUser = { ...currentUser, isChecked: true };
      const updatedUsersTable = currentGroupUsers.map((el) =>
        el.id === changedUser.id ? changedUser : el
      );
      setCurrentGroupUsers(updatedUsersTable);
    } else if (currentUser.isChecked === true) {
      const changedUser = { ...currentUser, isChecked: false };
      const updatedUsersTable = currentGroupUsers.map((el) =>
        el.id === changedUser.id ? changedUser : el
      );
      setCurrentGroupUsers(updatedUsersTable);
    }
  };

  function onAddNewUsers() {
    const usersArr = currentGroupUsers.map((el) => el.id);
    const body = {
      users: usersArr,
    };
    requestHelper(Moderators.updateModerators, body)
      .then((res) => {
        if (res.status === "ok") {
          console.log(res.status);
        } else {
          runUpdateDataEvents();
          console.log(res.text);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      })
      .finally(() => {
        runUpdateDataEvents();
      });
    usersTextarea.setValue("");
    setEmailErrorMessage("");
    setActiveAddUsersForm(false);
  }

  function onHandleShowCancelBtn() {
    usersTextarea.setValue("");
    setEmailErrorMessage("");
    setActiveAddUsersForm(false);
    usersTextarea.setValue("");
  }

  function onRemoveUserClick(user) {
    const filteredUsers = currentGroupUsers.filter((el) => el.id !== user.id);
    setCurrentGroupUsers(filteredUsers);
    const body = {
      users_to_delete: [user.id],
    };
    requestHelper(Moderators.updateModerators, body)
      .then((res) => {
        if (res.status === "ok") {
          console.log(res.status);
        } else {
          runUpdateDataEvents();
          console.log(res.text);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      })
      .finally(() => {
        runUpdateDataEvents();
      });
    closeModalDeleteUser();
  }

  function onRemoveSomeUsersClick() {
    const filtredIsCheckedArr = currentGroupUsers.filter(
      (el) => el.isChecked === true
    );
    const usersToDelete = filtredIsCheckedArr.map((el) => el.id);
    const filteredUsers = currentGroupUsers.filter(
      (el) => el.isChecked === false
    );
    setCurrentGroupUsers(filteredUsers);
    const body = {
      users_to_delete: usersToDelete,
    };
    requestHelper(Moderators.updateModerators, body)
      .then((res) => {
        if (res.status === "ok") {
          console.log(res.status);
        } else {
          runUpdateDataEvents();
          console.log(res.text);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      })
      .finally(() => {
        runUpdateDataEvents();
      });
    setActiveModalDeleteSomeUsers(false);
  }

  function handleShowResultsFrom(value) {
    setShowResultsFrom(value);
  }

  function handleResultsShow(value) {
    setResultsShow(value);
  }

  function showPrevResults() {
    if (resultsShow <= result) {
      return resultsShow;
    } else {
      setShowResultsFrom(showResultsFrom - result);
      handleShowResultsFrom(showResultsFrom - result);
      setResultsShow(resultsShow - result);
      handleResultsShow(resultsShow - result);
      setPageCount(pageCount - 1);
    }
  }

  function showNextResults() {
    if (resultsShow >= usersForRender.length) {
      return resultsShow;
    } else {
      setShowResultsFrom(0 + resultsShow);
      handleShowResultsFrom(0 + resultsShow);
      setResultsShow(result + resultsShow);
      handleResultsShow(result + resultsShow);
      setPageCount(pageCount + 1);
    }
  }

  function onChoiceClick(value) {
    setResultsShow(value);
    handleResultsShow(value);
    setResult(value);
    setSelectedResultsShow(value);
    setShowResultsFrom(0);
    handleShowResultsFrom(0);
    setPageCount(1);
  }

  function searchInput(value) {
    setUsersSearchInput(value);
  }

  useEffect(() => {
    let isMounted = true;
    if (usersSearchInput === "") {
      isMounted && onChoiceClick(5);
      isMounted && setUsersForRender(currentGroupUsers);
    } else {
      const dataForRender = [];
      currentGroupUsers.forEach((user) => {
        if (
          user?.email.toLowerCase().includes(usersSearchInput.toLowerCase())
        ) {
          isMounted && dataForRender.push(user);
        } else if (
          user?.last_name.toLowerCase().includes(usersSearchInput.toLowerCase())
        ) {
          isMounted && dataForRender.push(user);
        } else if (
          user?.first_name
            .toLowerCase()
            .includes(usersSearchInput.toLowerCase())
        ) {
          isMounted && dataForRender.push(user);
        } else if (
          user?.second_name
            .toLowerCase()
            .includes(usersSearchInput.toLowerCase())
        ) {
          isMounted && dataForRender.push(user);
        }
      });
      isMounted && onSetCountPageForSearch(dataForRender.length, onChoiceClick);
      isMounted && setUsersForRender(dataForRender);
    }
    return () => {
      isMounted = false;
    };
  }, [usersSearchInput, currentGroupUsers]);

  return (
    <div className="container__list-moderators _container">
      <GeneralTitleAllPages
        titleName={constants.LIST_MODERATORS.LIST_MODERATORS_TITLE}
        firstLetter={constants.GENERAL_TITLE.GENERAL_TITLE_FIRTSLETTER}
        secondLetter={
          constants.GENERAL_TITLE.GENERAL_TITLE_SECONDLETTER_LIST_MODERATORS
        }
      />
      <div
        className={
          activeAddUsersForm
            ? "list-moderators__add-btn-block hidden"
            : "list-moderators__add-btn-block"
        }
      >
        <button
          onClick={() => setActiveAddUsersForm(true)}
          className="list-moderators__button"
        >
          {constants.LIST_MODERATORS.LIST_MODERATORS_ADD_MODERATOR}
        </button>
      </div>
      {activeAddUsersForm && (
        <div className="list-moderators__add-users-form">
          <textarea
            className="list-moderators__input-field"
            placeholder={
              "Список почтовых адресов пользователей (XX@YY.ZZ) по одному на каждую строчку для каждого пользователя"
            }
            onChange={usersTextarea.onChange}
            value={usersTextarea.value}
          ></textarea>
          <p className="list-moderators__add-users-form-error-message">
            {emailErrorMessage}
          </p>
          <div className="list-moderators__add-users-form-buttons">
            <button
              onClick={findUsers}
              className="add-users-form-buttons__search-base-users-btn"
            >
              {constants.GROUP_USERS.GROUP_USERS_FIND_USERS_IN_BASE}
            </button>
            {usersTextarea.value !== "" && (
              <button
                onClick={onAddNewUsers}
                className="add-users-form-buttons__save-btn"
              >
                {constants.GROUP_USERS.GROUP_USERS_SAVE_BUTTON}
              </button>
            )}
            {usersTextarea.value === "" && (
              <button
                onClick={onHandleShowCancelBtn}
                className="add-users-form-buttons__save-btn"
              >
                {constants.GROUP_USERS.GROUP_USERS_MODAL_CANCEL}
              </button>
            )}
          </div>
        </div>
      )}
      <div className="list-moderators__pagination">
        <PaginationBlock
          sortList={usersForRender}
          search={usersSearch}
          searchInput={searchInput}
          onChoiceClick={onChoiceClick}
          selectedResultsShow={selectedResultsShow}
          pageCount={pageCount}
          showPrevResults={showPrevResults}
          showNextResults={showNextResults}
          constants={constants}
        />
      </div>
      {usersForRender.length !== 0 ? (
        <div className="list-moderators__table-users-select-name-group">
          <div className="table-users-select-name-group">
            {usersForRender
              .sort(function (a, b) {
                var emailA = a.email.toLowerCase(),
                  emailB = b.email.toLowerCase();
                if (emailA < emailB) return -1;
                if (emailA > emailB) return 1;
                return 0;
              })
              .slice(showResultsFrom, resultsShow)
              .map((el) => (
                <div
                  key={el?.id}
                  className="table-users-select-name-group__table-list-users"
                >
                  <p className="table-users-select-name-group__column-name">
                    {el?.last_name === undefined
                      ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}`
                      : el?.last_name}{" "}
                    {el?.first_name === undefined
                      ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}`
                      : el?.first_name}{" "}
                    {el?.second_name === undefined
                      ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}`
                      : el?.second_name}
                  </p>
                  <p className="table-users-select-name-group__column-e-mail">
                    {el?.email}
                  </p>
                  <div className="table-users-select-name-group__delete-icon-button">
                    {isActiveModalDeleteSomeUsers === true ? (
                      <img
                        onClick={() => onHandleCheckTable(el?.id)}
                        alt={constants.ALT_ICON}
                        src={el?.isChecked ? iconSelectName : iconDeleteBnt}
                        className="table-users-select-name-group__icon-delete"
                      />
                    ) : null}
                    {isActiveModalDeleteSomeUsers === false ? (
                      <p
                        onClick={() => setActiveModalDeleteUsers(el?.id)}
                        className="table-users-select-name-group__delete-btn"
                      >
                        {constants.ADD_NEW_ORG.ADD_NEW_ORG_DELETE_BTN}
                      </p>
                    ) : null}
                    {isActiveModalDeleteSomeUsers === false ? (
                      <button
                        onClick={() => setActiveModalDeleteUsers(el?.id)}
                        className="table-users-select-name-group__delete-btn-mobile"
                      >
                        {constants.ADD_NEW_ORG.ADD_NEW_ORG_DELETE_BTN_MOBILE}
                      </button>
                    ) : null}
                  </div>
                  <GroupUsersDeleteModalUsers
                    closeModal={closeModalDeleteUser}
                    isActiveModalDeleteUsers={isActiveModalDeleteUsers}
                    onRemoveUserClick={onRemoveUserClick}
                    user={el}
                    constants={constants}
                    onOpenModalDeleteSomeUsers={onOpenModalDeleteSomeUsers}
                  />
                </div>
              ))}
          </div>
        </div>
      ) : (
        <>
          <div className="table-list-users__no-users-container">
            <p className="table-list-users__no-users">
              {constants.ADD_NEW_ORG.ADD_NEW_ORG_NO_USERS}
              <span className="name-org-input__red-star"> *</span>
            </p>
          </div>
        </>
      )}
      {isActiveModalDeleteSomeUsers && (
        <GroupUsersDeleteModalSomeUsers
          constants={constants}
          closeModalDeleteSomeUsers={closeModalDeleteSomeUsers}
          onRemoveSomeUsersClick={onRemoveSomeUsersClick}
          currentGroupUsers={currentGroupUsers}
        />
      )}
      <div className="list-moderators__pagination">
        <PaginationBlock
          sortList={usersForRender}
          search={usersSearch}
          searchInput={searchInput}
          onChoiceClick={onChoiceClick}
          selectedResultsShow={selectedResultsShow}
          pageCount={pageCount}
          showPrevResults={showPrevResults}
          showNextResults={showNextResults}
          constants={constants}
        />
      </div>
    </div>
  );
};
export default ListModerators;
