import React, { useEffect, useRef, useState } from "react";

const CardQuestionHorizontalGraphColumnRow = ({
  result,
  colorColumns,
  votedUsers,
  votedPercent,
}) => {
  const [barLength, setBarLength] = useState(0);

  useEffect(() => {
    result > 0 ? setBarLength((result / votedUsers) * 100) : setBarLength(1);
  }, [votedUsers, result]);

  let widthRectColumn = result;

  return (
    <div className={"card-question-horizontal-graph-column-row__columns"}>
      <svg className={"card-question-horizontal-graph-column-row__column-svg"}>
        <g>
          <rect width={`${barLength}%`} height={"18"} fill={colorColumns} />
        </g>
      </svg>

      {/* <div
        style={{
          width: `${barLength}%`,
          height: 58,
          background: colorColumns,
          borderRadius: "0 8px 8px 0",
        }}
      ></div> */}
      <span
        className={
          "card-question-horizontal-graph-column-row__columns-text-percent active"
        }
      >
        {widthRectColumn} ({votedPercent}%)
      </span>
    </div>
  );
};

export default CardQuestionHorizontalGraphColumnRow;
