import * as Events from "../../Api/Events";
import { Qr } from "../Qr/Qr";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import PreLoaderBlock from "../PreLoaderBlock/PreLoaderBlock";
import "./QrLogins.css";
import { Navigate } from "react-router-dom";
// Create Document Component
const QrLogins = ({ requestHelper }) => {
  const [qrData, setQrData] = useState([]);
  const [eventForQrInfo, setEventForQrInfo] = useState({});
  const { id } = useParams();
  const { search } = useLocation();

  useEffect(() => {
    let isMouted = true;

    if (id && search) {
      requestHelper(Events.getQRLogins, { id: id, lifeTime: search })
        .then((data) => {
          isMouted && setQrData(data);
          isMouted && setTimeout(() => window.print(), 0);
        })
        .catch((err) => {
          throw new Error(err?.message);
        });

        const body = {
          id: id,
        };

      requestHelper(Events.getEvent, body)
        .then((data) => {
          isMouted && setEventForQrInfo(data)
        })
        .catch((err) => {
          throw new Error(err?.message);
        });
    }

    return () => {
      isMouted = false;
    }

  }, [id, search]);
  
  return qrData.length > 0 ? (
    <div className="qr-logins">
      {qrData.map((it) => {
        return (
          <div key={it.link} className="qr-logins__section">
            <h1>{eventForQrInfo?.title}</h1>
            <p className="qr-logins-instruction"><b>Шаг 1.</b>Отсканируйте QR-код камерой телефона</p>
            <Qr addData={it.link} />
            <p className="qr-logins-instruction">или откройте страницу</p>
            <p className="qr-logins-link">{it.link}</p>
            <p className="qr-logins-instruction">и введите личный пин-код:</p>
            <p className="qr-logins-pin-code">
              <mark className="qr-logins-pin-code-mark">{it.link.slice(-8, -4)} - {it.link.slice(-4)}</mark>
              </p>
            <p className="qr-logins-instruction"><b>Шаг 2.</b>Ожидайте начала голосования</p>
          </div>
        );
      })}
    </div>
  ) : qrData.status === "failure" ? (
    <Navigate to="/" />
  ) : (
    <PreLoaderBlock />
  );
};

export default QrLogins;
