import {useEffect, useState} from "react";

function useSetRuleQuestionLE(startValueInput, endValueInput, activeRuleSelect, rows) {

    const [valueRuleLE, setValueRuleLE] = useState(0);

    useEffect(() => {
        let isMount = true;

        if (activeRuleSelect === true && startValueInput === 1 && endValueInput < rows.length && startValueInput !== endValueInput) {
            isMount && setValueRuleLE(endValueInput)
        } else {
            isMount && setValueRuleLE(-1);
        }

        return () => {
            isMount = false;
        }
    },[startValueInput, endValueInput, activeRuleSelect, rows])

    return {valueRuleLE};
}

export default useSetRuleQuestionLE;