import {useEffect, useState} from "react";

function useActiveOrgListProfileUser(isUserProfile, currentUser) {

    const [activeListOrgEvent, setActiveListOrgEvent] = useState(false);
    const getRoleCurrentUser = currentUser?.authorities?.map(el => el?.role)

    useEffect(() => {
        let isMount = true;
            if (getRoleCurrentUser?.join() === 'ROLE_SUPER_ADMIN') {
                isMount && setActiveListOrgEvent(true);
            } else if(isUserProfile?.id === currentUser?.id) {
                isMount && setActiveListOrgEvent(true);
            } else {
                isMount && setActiveListOrgEvent(false);
            }
        return () => {
            isMount = false;
        }
    },[isUserProfile, currentUser ])

    return { activeListOrgEvent };
}
export default useActiveOrgListProfileUser;