import React, { useEffect, useRef, useState } from "react";

const HorizontalGraphColumnGrid = (props) => {
  const { resultValue, nameColumns, colorColumns, allVotes } = props;

  const [hideSpanPercent, setHideSpanPercent] = useState(false);
  const textSvgRef = useRef(null);

  let widthRectColumn = resultValue;

  function movingTextOneColumn(x) {
    let sum;
    if (x > 350) {
      sum = x - 150;
    } else {
      sum = x;
    }
    return sum;
  }

  useEffect(() => {
    movingTextOneColumn(widthRectColumn);
    if (widthRectColumn > 450) {
      setHideSpanPercent(true);
      textSvgRef.current.style.display = "none";
    } else {
      setHideSpanPercent(false);
      textSvgRef.current.style.display = "initial";
    }
  }, [widthRectColumn]);

  return (
    <>
      <svg className={"card-question-horizontal-graph-column-grid__column-svg"}>
        <g>
          <rect
            width={`${(resultValue / allVotes) * 100}%`}
            height={"28"}
            fill={colorColumns}
          />
          <text
            ref={textSvgRef}
            x={12}
            y={"50%"}
            fontSize={14}
            fontWeight={"bold"}
            fill={"rgba(54, 59, 77, 1)"}
          >
            {nameColumns} - {widthRectColumn} (
            {`${(resultValue / allVotes) * 100}%`})
          </text>
        </g>
      </svg>
      <span
        className={
          hideSpanPercent
            ? "card-question-horizontal-graph-column-grid__columns-text-percent active"
            : "card-question-horizontal-graph-column-grid__columns-text-percent"
        }
      >
        <span className={"grid__columns-text-percent"}>{nameColumns}</span> -{" "}
        {widthRectColumn} ({`${(resultValue / allVotes) * 100}%`})
      </span>
    </>
  );
};
export default HorizontalGraphColumnGrid;
