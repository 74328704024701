import { config } from "../../config";
import { Validation } from "../../utils/Validation/Validation";
import { useEffect, useState } from "react";
import show_pass_icon from "../../img/Auth_show_pass_icon.svg";
import hidden_pass_icon from "../../img/Auth_hidden_pass.svg";
import classNames from "classnames";

const AuthByEmail = ({
  authError,
  authTypes,
  email,
  emailPassword,
  emailCode,
  setPasswordType,
  authErrorMessage,
}) => {
  const activeTypeDisplay = config.type;

  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [second, setSecond] = useState(60);

  const [passwordView, setPasswordView] = useState(authTypes.emailPassword);

  const [newCodeMessage, setNewCodeMessage] = useState(false);

  // меняет тип пароля для логина
  useEffect(() => {
    setPasswordType(passwordView ? "password" : "temporary-code");
  }, [passwordView]);

  return (
    <>
      <div className="form__login">
        {authTypes.emailOnly && (
          <div className="auth-form__select-email-phone-container">
            <span>E-mail</span>
          </div>
        )}

        <input
          type="email"
          name="email"
          placeholder={activeTypeDisplay ? "user@mail.ru" : "stxxxxxx"}
          required
          value={email.value}
          onChange={email.onChange}
          className={authErrorMessage ? "error-border" : ""}
        />
      </div>

      <div className="form__pass">
        {authTypes.emailPasswordAndCode && (
          <div className="auth-form__select-email-phone-container">
            Выполнить вход по:
            <p
              onClick={() => setPasswordView(true)}
              className={
                passwordView
                  ? "auth-form__select-email active"
                  : "auth-form__select-email"
              }
            >
              Паролю
            </p>
            /
            <p
              onClick={() => setPasswordView(false)}
              className={
                !passwordView
                  ? "auth-form__select-phone active"
                  : "auth-form__select-phone"
              }
            >
              Коду на почту
            </p>
          </div>
        )}

        {(passwordView || authTypes.emailPasswordOnly) &&
          authTypes.emailPassword && (
            <div className="form__pass-container">
              <img
                alt="иконка"
                className="form__pass-show-pass-icon"
                src={hiddenPassword ? show_pass_icon : hidden_pass_icon}
                onClick={() => setHiddenPassword(!hiddenPassword)}
              />

              <input
                type={hiddenPassword ? "password" : "text"}
                name="password"
                placeholder="Введите пароль"
                required
                value={emailPassword.value}
                onChange={emailPassword.onChange}
                className={classNames({
                  "error-border": authErrorMessage,
                })}
              />
            </div>
          )}

        {(!passwordView || authTypes.emailCodeOnly) && (
          <div className="auth__form-phone-sms-code-block">
            <button
              type={"button"}
              onClick={() => {
                // получить код на почту
              }}
              className={"auth__form-phone-sms-code-btn"}
            >
              Получить код
            </button>
            <input
              className={classNames({
                "auth__form-phone-sms-fields": true,
                // hidden: !activeSMSField,
                "error-border": authErrorMessage,
              })}
              placeholder="Код"
              type="text"
              required
              minLength="4"
              maxLength="4"
              value={emailCode.value}
              onChange={emailCode.onChange}
            />
            <p
              className={
                newCodeMessage
                  ? "auth__form-phone-sms-message"
                  : "auth__form-phone-sms-message hidden"
              }
            >
              Вы так и не получили код? Повторно код можно получить через{" "}
              {second} сек
            </p>
          </div>
        )}
      </div>
      {authError && (
        <div className="form__pass">
          <div className="form__error">{authError}</div>
        </div>
      )}
    </>
  );
};

export default AuthByEmail;
