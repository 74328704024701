import React, { useEffect, useState } from "react";
import PaginationBlock from "../PaginationBlock/PaginationBlock";
import { Validation } from "../../utils/Validation/Validation";
import { onSetCountPageForSearch } from "../../utils/OnSetCountPageForSearch/OnSetCountPageForSearch";

const UsersSettings = (props) => {
  const { constants, isOrganizationUsers } = props;

  const [isOrgListUsers, setOrgListUser] = useState([]);
  const usersSearch = Validation();
  const [usersForRender, setUsersForRender] = useState([]);
  const [usersSearchInput, setUsersSearchInput] = useState("");
  const [showResultsFrom, setShowResultsFrom] = useState(0);
  const [resultsShow, setResultsShow] = useState(5);
  const [result, setResult] = useState(5);
  const [pageCount, setPageCount] = useState(1);
  const [selectedResultsShow, setSelectedResultsShow] = useState(5);

  useEffect(() => {
    let isMounted = true;
    if (isOrganizationUsers.length !== 0) {
      const users = [];
      isOrganizationUsers.forEach((user) => {
        if (user.lastName === undefined) {
          const newUser = {
            id: user.id,
            email: user.email,
            firstName: `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}`,
            lastName: `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}`,
            secondName: `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}`,
            isAdmin: user.isAdmin,
            isSuperUser: user.isSuperUser,
          };
          users.push(newUser);
        } else {
          const newUser = {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            secondName: user.secondName,
            isAdmin: user.isAdmin,
            isSuperUser: user.isSuperUser,
          };
          users.push(newUser);
        }
      });
      isMounted && setOrgListUser(users);
    }
    return () => {
      isMounted = false;
    };
  }, [isOrganizationUsers]);

  function handleShowResultsFrom(value) {
    setShowResultsFrom(value);
  }

  function handleResultsShow(value) {
    setResultsShow(value);
  }

  function showPrevResults() {
    if (resultsShow <= result) {
      return;
    } else {
      setShowResultsFrom(showResultsFrom - result);
      handleShowResultsFrom(showResultsFrom - result);
      setResultsShow(resultsShow - result);
      handleResultsShow(resultsShow - result);
      setPageCount(pageCount - 1);
    }
  }

  function showNextResults() {
    if (resultsShow >= usersForRender.length) {
      return;
    } else {
      setShowResultsFrom(0 + resultsShow);
      handleShowResultsFrom(0 + resultsShow);
      setResultsShow(result + resultsShow);
      handleResultsShow(result + resultsShow);
      setPageCount(pageCount + 1);
    }
  }

  function onChoiceClick(value) {
    setResultsShow(value);
    handleResultsShow(value);
    setResult(value);
    setSelectedResultsShow(value);
    setShowResultsFrom(0);
    handleShowResultsFrom(0);
    setPageCount(1);
  }

  function searchInput(value) {
    setUsersSearchInput(value);
  }

  useEffect(() => {
    let isMounted = true;
    if (usersSearchInput === "") {
      isMounted && setUsersForRender(isOrgListUsers);
      isMounted && onChoiceClick(5);
    } else {
      const dataForRender = [];
      isOrgListUsers.forEach((user) => {
        if (
          user?.email?.toLowerCase().includes(usersSearchInput.toLowerCase())
        ) {
          dataForRender.push(user);
        } else if (
          user?.lastName?.toLowerCase().includes(usersSearchInput.toLowerCase())
        ) {
          dataForRender.push(user);
        } else if (
          user?.firstName
            ?.toLowerCase()
            .includes(usersSearchInput.toLowerCase())
        ) {
          dataForRender.push(user);
        } else if (
          user?.secondName
            ?.toLowerCase()
            .includes(usersSearchInput.toLowerCase())
        ) {
          dataForRender.push(user);
        }
      });
      isMounted &&
        onSetCountPageForSearch(dataForRender?.length, onChoiceClick);
      isMounted && setUsersForRender(dataForRender);
    }
    return () => {
      isMounted = false;
    };
  }, [usersSearchInput, isOrgListUsers]);

  return (
    <div className="users-settings">
      <h2 className="users-settings__heading">
        {constants.ORG_SETTINGS.USERS_LIST}
      </h2>
      <div className="users-table">
        <div className="add-new-organization__top-pagination">
          <PaginationBlock
            sortList={usersForRender}
            search={usersSearch}
            searchInput={searchInput}
            onChoiceClick={onChoiceClick}
            selectedResultsShow={selectedResultsShow}
            pageCount={pageCount}
            showPrevResults={showPrevResults}
            showNextResults={showNextResults}
            constants={constants}
          />
        </div>
        {usersForRender.length !== 0 ? (
          <div className="add-new-organization__table-list-users">
            <div className="users-settings-table-header">
              <p className="users-settings-table-header__e-mail">
                {constants.ORG_SETTINGS.USER_LIST_USERNAME_EMAIL}
              </p>
              <p className="users-settings-table-header__username-data">
                {constants.ORG_SETTINGS.USER_LIST_USERNAME_DATA}
              </p>
              <p className="users-settings-table-header__role-admin">
                {constants.ORG_SETTINGS.USERS_LIST_USERNAME_ROLE_ADMIN}
              </p>
              <p className="users-settings-table-header__role-superadmin">
                {constants.ORG_SETTINGS.USERS_LIST_USERNAME_ROLE_SUPERUSER}
              </p>
            </div>
            {usersForRender
              .sort(function (a, b) {
                var emailA = a?.email?.toLowerCase(),
                  emailB = b?.email?.toLowerCase();
                if (emailA < emailB) return -1;
                if (emailA > emailB) return 1;
                return 0;
              })
              .slice(showResultsFrom, resultsShow)
              .map((user, i) => (
                <div key={i} className="table-list-users">
                  <p className="users-settings-table__e-mail">{user?.email}</p>
                  <p className="users-settings-table__username-data">
                    {user?.lastName} {user?.firstName} {user?.secondName}
                  </p>
                  <p className="users-settings-table__role-admin">
                    {user?.isAdmin ? "+" : "-"}
                  </p>
                  <p className="users-settings-table__role-admin-mobile">
                    Секретарь {user?.isAdmin ? "+" : "-"}
                  </p>
                  <p className="users-settings-table__role-superadmin">
                    {user?.isSuperUser ? "+" : "-"}
                  </p>
                  <p className="users-settings-table__role-superadmin-mobile">
                    Суперпользователь {user?.isSuperUser ? "+" : "-"}
                  </p>
                </div>
              ))}
          </div>
        ) : (
          <div className="table-list-users__no-users-container">
            <p className="table-list-users__no-users">
              {constants.ADD_NEW_ORG.ADD_NEW_ORG_NO_USERS}
              <span className="name-org-input__red-star"> *</span>
            </p>
          </div>
        )}
        <div className="add-new-organization__bottom-pagination">
          <PaginationBlock
            sortList={usersForRender}
            search={usersSearch}
            searchInput={searchInput}
            onChoiceClick={onChoiceClick}
            selectedResultsShow={selectedResultsShow}
            pageCount={pageCount}
            showPrevResults={showPrevResults}
            showNextResults={showNextResults}
            constants={constants}
          />
        </div>
      </div>
    </div>
  );
};
export default UsersSettings;
